/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { format } from 'date-fns';
import { Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import slugify from 'slugify';
import { ReadTimeBadge } from './ui/badge/badge';

export default function Headers({ post, titleSuffix, book }) {
  const NoHeaderImage =
    !post.frontmatter.layout ||
    post.frontmatter.layout === 'book' ||
    post.frontmatter.layout === 'post';
  const image = getImage(post.frontmatter.image);
  const imgGradientStack = ['linear-gradient(rgba(5,15,10,.7),rgba(5,15,10,.7))', image];

  return (
    <div>
      {' '}
      {NoHeaderImage && (
        <div className="container py-12 md:pt-24 md:pb-6">
          <h1
            className="font-bold text-3xl md:text-5xl text-center"
            css={css`
              color: var(--textNormal);
            `}
          >
            {post.frontmatter.title}
            {titleSuffix}
          </h1>
          <div
            className="text-gray-700 flex flex-wrap justify-center items-center"
            css={css`
              span {
                margin: 0 0 0.5rem 0;
                color: var(--textLabel);
              }
              span:not(:last-of-type):after {
                content: '|';
                color: var(--border);
                padding: 0.5rem;
              }
            `}
          >
            {post.frontmatter.date && (
              <span className="font-mono text-sm">
                <i className="far fa-calendar mr-2" />
                {format(new Date(post.frontmatter.date), 'MMMM do, yyyy')}
              </span>
            )}
            {post.fields.readingTime.text && (
              <ReadTimeBadge noBackground noIcon timeToRead={post.fields.readingTime.text} />
            )}
            <span>
              {post.frontmatter.categories &&
                post.frontmatter.categories.slice(0, 2).map((item, i) => (
                  <Link
                    to={`/${book ? 'book-category' : 'category'}/${slugify(item.toLowerCase())}`}
                    className="font-mono text-sm hover:text-primary-700 text-sm mb-1 font-semibold uppercase tracking-wide"
                    key={item}
                  >
                    {i !== 0 && <span className="mr-1">,</span>}
                    {item}
                  </Link>
                ))}
            </span>
          </div>
        </div>
      )}
      {post.frontmatter.layout === 'post-dark' && (
        <BgImage image={imgGradientStack}>
          <div className="container pb-12 pt-24 md:pb-24 md:pt-40">
            <h1 className="text-white font-bold text-3xl md:text-5xl mt-0">
              {post.frontmatter.title}
            </h1>
            <div className="text-white">
              {post.frontmatter.categories &&
                post.frontmatter.categories.slice(0, 2).map((item, i) => (
                  <Link
                    to={`/category/${slugify(item.toLowerCase())}`}
                    className="font-mono text-sm hover:text-primary-700 text-sm mb-1 font-semibold uppercase tracking-wide"
                    key={item}
                  >
                    {i !== 0 && <span className="mr-1">,</span>}
                    {item}
                  </Link>
                ))}
            </div>
            <div
              className="text-gray-400"
              css={css`
                span:not(:last-of-type):after {
                  content: '/';
                  padding: 0 0.75rem;
                }
              `}
            >
              {post.frontmatter.date && (
                <span className="font-mono text-sm">
                  <i className="far fa-calendar mr-2" />
                  {format(new Date(post.frontmatter.date), 'MMMM do, yyyy')}
                </span>
              )}
              {post.fields.readingTime.text && (
                <ReadTimeBadge noBackground noIcon timeToRead={post.fields.readingTime.text} />
              )}
            </div>
          </div>
        </BgImage>
      )}
      {post.frontmatter.layout === 'post-light' && (
        <BgImage
          style={{
            height: '600px',
            width: '100%',
          }}
          image={[image]}
        />
      )}
    </div>
  );
}
