import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { useId } from 'react-id-generator';
import getPostsFromQuery from '../../utils/graphql-utils';
import { TextRelated } from '../ui/related/related';
import SimilarPostsFactory from './SimilarPostsFactory';

const SimilarPostsComponent = ({ posts }) => {
  const similarPostKeys = useId(posts.length);
  return (
    <section className="flex flex-col items-center">
      <h3 className="uppercase text-xl text-gray-600 tracking-wide">Related Posts</h3>
      <ul className="flex w-full justify-between">
        {posts.map((post, i) => {
          const { title, slug } = post.article;
          return (
            <li
              className="font-mono hover:text-blue-500 hover:underline list-none w-1/3 px-2 text-center"
              key={similarPostKeys[i]}
            >
              <TextRelated title={title} slug={slug} />
            </li>
          );
        })}
      </ul>
    </section>
  );
};

// (1.) Query for books
export default (props) => (
  <StaticQuery
    query={graphql`
      query SimilarPosts {
        posts: allBlogPost(
          sort: { fields: frontmatter___date, order: DESC }
          filter: { frontmatter: { image: { relativePath: { ne: null } } } }
        ) {
          totalCount
          nodes {
            frontmatter {
              title
              categories
              tags
              slug
            }
          }
        }
      }
    `}
    render={(data) => {
      const { categories, tags, currentSlug } = props;
      const slug = currentSlug && currentSlug.replace(/$\//, '');
      // (2.) Marshall the response into articles
      const posts = getPostsFromQuery(data.posts);
      // (3.) Use a SimilarArticlesFactory to get my similar articles
      const similarPosts = new SimilarPostsFactory(posts, slug)
        .setMaxPosts(3)
        .setCategories(categories)
        .setTags(tags)
        .getPosts();

      // (4.) Render it
      return <SimilarPostsComponent posts={similarPosts} />;
    }}
  />
);
