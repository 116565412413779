/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { MDXProvider } from '@mdx-js/react';
import { format } from 'date-fns';
import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import slugify from 'slugify';
import Headers from '../components/Headers';
import Layout from '../components/Layout';
import NewsletterForm from '../components/NewsletterFormSidebar';
import SEO from '../components/seo/seo';
import ShareArticle from '../components/template/ShareArticle';
import SimilarArticles from '../components/template/SimilarArticles';
import Author from '../components/ui/avatar/author';
import { ReadTimeBadge } from '../components/ui/badge/badge';
import { TextCard } from '../components/ui/card/card';
import CardNav from '../components/ui/card/cardNav';
import Divider from '../components/ui/divider/divider';

export default function BlogPostTemplate({ data: { post }, pageContext }) {
  const { prevPost, nextPost } = pageContext;

  const PrevCard = prevPost
    ? () => <TextCard prev title={prevPost.frontmatter.title} slug={prevPost.frontmatter.slug} />
    : null;
  const NextCard = nextPost
    ? () => <TextCard next title={nextPost.frontmatter.title} slug={nextPost.frontmatter.slug} />
    : null;

  const shortcodes = { Link };

  return (
    <Layout progress>
      <SEO frontmatter={post.frontmatter} isBlogPost />
      <Headers post={post} />
      <div className="container pt-8 pb-12 font-body">
        <div className="flex flex-wrap justify-center">
          {post.frontmatter.layout === 'post-light' && (
            <section className="flex-col items-center">
              <h1 className="font-bold text-center text-3xl md:text-5xl mt-0">
                {post.frontmatter.title}
              </h1>
              <Author position="left" />
              <div
                className="flex justify-center items-center mt-4"
                css={css`
                  color: var(--textNormal);
                `}
              >
                {post.frontmatter.date && (
                  <span className="font-mono text-sm px-4">
                    <i className="far fa-calendar mr-2" />
                    {format(new Date(post.frontmatter.date), 'MMMM do, yyyy')}
                  </span>
                )}
                {post.fields.readingTime.text && (
                  <ReadTimeBadge noBackground timeToRead={post.fields.readingTime.text} />
                )}
                <span className="flex px-4">
                  {post.frontmatter.categories &&
                    post.frontmatter.categories.slice(0, 2).map((item, i) => (
                      <Link
                        to={`/category/${slugify(item.toLowerCase())}`}
                        className="font-sans text-sm uppercase tracking-wide"
                        key={item}
                      >
                        {i !== 0 && <span className="mr-1">,</span>}
                        <span className="hover:text-primary-700">{item}</span>
                      </Link>
                    ))}
                </span>
              </div>
            </section>
          )}
          <div className="md:w-3/4 w-full">
            {' '}
            <MDXProvider components={shortcodes}>
              <MDXRenderer>{post.body}</MDXRenderer>
            </MDXProvider>
            {post.frontmatter.tags &&
              post.frontmatter.tags.slice(0, 2).map((item, i) => (
                <Link
                  to={`/tag/${slugify(item.toLowerCase())}`}
                  className="font-mono inline-block text-green-700 text-sm font-semibold lowercase"
                  key={item}
                >
                  {i !== 0 && <span className="px-2"> </span>}#{item.toLowerCase()}
                </Link>
              ))}
            {/* <AuthorBio /> */}
            <Divider position="bottom" />
            <NewsletterForm />
            <ShareArticle post={post} center />
            <Divider position="bottom" />
            <CardNav Prev={PrevCard} Next={NextCard} />
          </div>
          <SimilarArticles
            categories={post.frontmatter.categories}
            currentSlug={post.frontmatter.slug}
            tags={post.frontmatter.tags || []}
          />
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($id: String, $prevId: String, $nextId: String) {
    post: mdx(id: { eq: $id }) {
      body
      fields {
        readingTime {
          text
        }
      }
      frontmatter {
        author
        tags
        categories
        date(formatString: "DD MMMM YYYY")
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP])
          }
        }
        layout
        title
      }
    }
    prevPost: mdx(id: { eq: $prevId }) {
      frontmatter {
        slug
        title
      }
    }
    nextPost: mdx(id: { eq: $nextId }) {
      frontmatter {
        slug
        title
      }
    }
  }
`;
