/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import classNames from 'classnames';
import Avatar from './avatar';

export default ({ position }) => {
  const positionClass = classNames({
    'justify-center': position === 'center',
    'justify-start': position === 'start' || !position,
  });

  return (
    <section
      className={`flex ${positionClass} items-center content-center`}
      css={css`
        color: var(--textLabel);
      `}
    >
      <Avatar />
      <div className="flex flex-col">
        <span className="font-sans text-sm font-semibold ml-2">Juvoni Beckford</span>
        <a
          className="font-sans text-xs font-semibold text-gray-500 ml-2 tracking-wider hover:text-blue-500"
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.twitter.com/juvoni"
        >
          @juvoni
        </a>
      </div>
    </section>
  );
};
